import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeachersService {

  constructor(private httpClient: HttpClient) { }

  getStudents(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.serverUrl}/umbraco/api/TeachersApi/GetStudents`);
  }

  getStudentTests(studentId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.serverUrl}/umbraco/api/TeachersApi/GetStudentTests?studentId=${studentId}`);
  }

  addTeacherToStudent(studentIdentifier: string, firstName: string, lastName: string, classNumber: number = 0): Observable<any> {
    return this.httpClient.post<any>(`${environment.serverUrl}/umbraco/api/TeachersApi/AddTeacherToStudent?studentIdentifier=${studentIdentifier}&firstName=${firstName}&lastName=${lastName}&classNumber=${classNumber}`, null);
  }

  removeTeacherFromStudent(studentIdentifier: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.serverUrl}/umbraco/api/TeachersApi/RemoveTeacherFromStudent?studentIdentifier=${studentIdentifier}`, null);
  }

  getTeacherClassesAverages(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.serverUrl}/umbraco/api/TeachersApi/GetTeacherClassesAverage`);
  }

  getClassTests(classId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.serverUrl}/umbraco/api/TeachersApi/GetTeacherClassTests?classId=${classId}`);
  }

  getClassTestStudents(classId: number, testId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.serverUrl}/umbraco/api/TeachersApi/GetClassTestsStudents?classId=${classId}&testId=${testId}`);
  }

  getSettings(): Observable<any> {
    return this.httpClient.get<any>(`${environment.serverUrl}/umbraco/api/TeachersApi/GetSettings`);
  }

  updateSettings(settings: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.serverUrl}/umbraco/api/TeachersApi/UpdateSettings`, settings);
  }


  uploadImage(image: any): Observable<any> {

    return this.httpClient.post<string>(`${environment.serverUrl}/umbraco/api/TeachersApi/UploadImage`, { Base64String: image });
  }



}
